var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"appBarNavigationTitle"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.selectedCategory ? 'community.categoryItems.' + _vm.selectedCategory + '.title' : 'community.headline'),expression:"selectedCategory ? 'community.categoryItems.' + selectedCategory + '.title' : 'community.headline'"}]})]),_c('portal',{attrs:{"to":"appBarNavigationAction"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[(_vm.selectedCategory)?_c('div',[_c('BaseBackButton',{attrs:{"route":{name: 'CommunityList'}}})],1):_c('div',[_c('BaseBackButton')],1),_c('div',[_c('CommunitySearch',{model:{value:(_vm.searchTerm2),callback:function ($$v) {_vm.searchTerm2=$$v},expression:"searchTerm2"}})],1),(false && _vm.selectedCategory)?_c('div',[(!_vm.countryFilter)?_c('v-menu',{staticClass:"v-menu--inline",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',_vm._g({staticClass:"d-inline-flex",attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 btn--default-transform primary--text",attrs:{"color":"grey lighten-5","fab":"","small":"","depressed":""}},Object.assign({}, onTooltip,
                                        onMenu)),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-earth')}})],1)]}}],null,true)},onMenu),[_c('span',{directives:[{name:"t",rawName:"v-t",value:('community.countryFilterTooltip'),expression:"'community.countryFilterTooltip'"}]})])]}}],null,false,3013399117)},[_c('v-list',_vm._l((_vm.communityItemCountries),function(country){return _c('v-list-item',{key:country.countryCode,class:{
                                primary:
                                    _vm.countryFilter ===
                                    country.countryCode,
                            },on:{"click":function($event){_vm.countryFilter = country.countryCode}}},[_c('v-list-item-content',[_c('v-list-item-title',{class:{
                                        'white--text':
                                            _vm.countryFilter ===
                                            country.countryCode,
                                    }},[_vm._v(_vm._s(country.countryName))])],1)],1)}),1)],1):_vm._e(),(_vm.countryFilter)?_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 btn--default-transform primary--text",attrs:{"color":"grey lighten-5"},on:{"click":function($event){_vm.countryFilter = null}}},on),[_c('v-icon',{staticClass:"pt-0",attrs:{"left":"","size":"18"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.countryFilterName)+" ")],1)]}}],null,false,174379943)},[_c('span',[_vm._v(_vm._s(_vm.$t('community.resetCountryFilterTooltip')))])]):_vm._e()],1):_vm._e()])]),_c('v-container',{attrs:{"fluid":""}},[(!_vm.selectedCategory)?_c('v-row',{staticClass:"pt-12 fill-height",attrs:{"justify":"space-around"}},[(_vm.$wait.is('loading community teaser'))?_c('div',[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-loading mdi-spin")])],1):_vm._l((_vm.latestCommunityItems),function(category){return [(category.result.total > 0)?_c('v-col',{key:category.category,class:{'mb-12': _vm.$vuetify.breakpoint.lgOnly},attrs:{"cols":"12","lg":"6","xl":"4"}},[_c('LatestCommunityItemCategory',{attrs:{"category":category,"selectedItem":_vm.selectedItem,"selectedCategory":_vm.selectedCategory},on:{"selectCategory":function($event){return _vm.selectCategory(category.category)},"selectItem":function($event){return _vm.selectItem($event, true, true)}}})],1):_vm._e()]})],2):_vm._e(),_c('v-row',[(_vm.selectedCategory)?[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"11","md":"8","lg":"4","xl":"3"}},[(
                            _vm.selectedCategory &&
                                _vm.selectedTag &&
                                _vm.selectedTag.name
                        )?_c('CommunityCatalog',{ref:"communityFilterResult",attrs:{"selectedCategory":_vm.selectedCategory,"selectedTag":_vm.selectedTag,"selectedItem":_vm.selectedItem,"initialLoaded":_vm.itemInitialyLoaded,"departmentCategoryItems":_vm.departmentCategoryItems,"showLoadMore":_vm.itemShowLoadMore,"searchTerm":_vm.searchTerm,"countryFilter":_vm.countryFilter},on:{"setInitialyLoaded":function($event){return _vm.setInitialyLoaded($event)},"setShowLoadMore":function($event){return _vm.setShowLoadMore($event)},"selectItem":function($event){return _vm.selectItem($event)},"communityItemCountries":function($event){return _vm.setCommunityItemCountries($event)}}}):_vm._e()],1)]:_vm._e(),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"5","offset-lg":"1","offset-xl":"1"}},[(_vm.selectedItem)?_c('div',[(
                            _vm.$vuetify.breakpoint.lgAndUp &&
                                !_vm.forceModalPreview
                        )?_c('div',{staticClass:"position-lg-fixed setWidthFromFlex"},[_c('ItemPreview',{ref:"itemPreview",staticStyle:{"height":"calc(100vh - 150px)","overflow":"auto"},attrs:{"selectedItem":_vm.selectedItem,"forceModalPreview":_vm.forceModalPreview,"departmentCategoryItems":_vm.departmentCategoryItems},on:{"closeItem":_vm.closeItem}})],1):_vm._e()]):_vm._e()])],2)],1),(
            _vm.selectedItem &&
                (_vm.$vuetify.breakpoint.mdAndDown || _vm.forceModalPreview)
        )?_c('div',[_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.smAndDown
                    ? '90%'
                    : _vm.$vuetify.breakpoint.mdAndDown
                    ? '70%'
                    : _vm.$vuetify.breakpoint.lg
                    ? '60%'
                    : '30%'},model:{value:(_vm.itemPreviewDialog),callback:function ($$v) {_vm.itemPreviewDialog=$$v},expression:"itemPreviewDialog"}},[_c('ItemPreview',{attrs:{"selectedItem":_vm.selectedItem,"forceModalPreview":_vm.forceModalPreview,"departmentCategoryItems":_vm.departmentCategoryItems},on:{"closeItem":_vm.closeItem}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }