<template>
    <v-card
        class="transparent"
        flat
        ripple
        tile
        :elevation="activeElevation && isSelected ? 7 : 0"
    >
        <v-card-text
            @click="$emit('selectItem', item)"
            class="cursor-pointer"
            :class="{ 'px-0': removePadding }"
        >
            <div>
                <h3 class="text-subtitle-1 mb-1 primary--text">
                    {{ item.heading }}
                </h3>
                <p
                    v-if="item.category === 'template' && item.nameOfAuthority"
                    class="text-caption ma-0 pb-2"
                >
                    {{ $t('community.authority') }} {{ item.nameOfAuthority }}
                </p>
                <div v-if="item.category !== 'lea_news'" class="text-justify text-smaller">
                    {{ $htmlSnippet(textContent, 220) }}
                </div>
                <p
                    v-if="item.category === 'lea_news' && item.subheading"
                    class="text-caption ma-0 pb-2"
                >
                    {{ item.subheading }}
                </p>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        item: Object,
        selectedItem: Object,
        isSelected: Boolean,
        activeElevation: {
            type: Boolean,
            default: false,
        },
        removePadding: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    computed: {
        textContent() {
            if (this.item.summary && this.item.summary.length > 8) {
                return this.item.summary;
            }
            return this.item.content;
        },
    }
};
</script>
