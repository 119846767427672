<template>
    <div>
        <div v-if="$wait.is('loading community catalog')" class="pt-3">
            <v-icon color="primary" large>mdi-loading mdi-spin</v-icon>
        </div>
        <template v-for="groupItem in groupedCommunityItems">
            <div :key="groupItem.group">
                <v-card-title class="h3 primary--text pt-3">{{ getGroupName(groupItem.group) }}</v-card-title>
                <div>
                    <template v-for="communityItem in groupItem.items">
                        <CommunityResultPreview
                            :key="communityItem.id"
                            :item="communityItem"
                            :selectedItem="selectedItem"
                            activeElevation
                            :isSelected="
                                selectedItem && communityItem.id === selectedItem.id
                            "
                            @selectItem="$emit('selectItem', $event)"
                        />
                    </template>
                </div>
            </div>
        </template>
        <div class="clear pt-3">
            <v-btn
                v-if="showLoadMore"
                block
                @click="loadMore()"
                :disabled="$wait.is('loading community catalog')"
                :loading="$wait.is('loading community catalog')"
                >{{ $t('community.loadMore') }}</v-btn
            >
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { groupBy, chain, uniq } from 'lodash';
import CommunityResultPreview from './CommunityResultPreview.vue';
import { parseISO, dateTypes, formatRelativeGroup } from '@/utils/dateFns';

export default {
    name: 'CommunityListCatalog',
    components: {
        CommunityResultPreview,
    },
    props: {
        searchTerm: {
            type: String,
            default: null,
        },
        countryFilter: {
            type: String,
            default: null,
        },
        selectedCategory: String,
        selectedTag: Object,
        selectedItem: Object,
        initialLoaded: {
            type: Boolean,
            default: false
        },
        showLoadMore: {
            type: Boolean,
            default: false
        },
        departmentCategoryItems: Array,
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    data() {
        return {
            page: 1,
            enableCheckbox: false,
            communityItems: [],
            totalItems: 0,
        };
    },
    mounted() {
        if (!this.initialLoaded) {
            this.resetAndLoadItems();
        }
    },
    computed: {
        isGroupedBy() {
            return this.selectedCategory === 'template'
                ? 'name'
                : this.selectedCategory === 'scenario'
                ? 'department'
                : 'date';
        },
        groupedCommunityItems() {
            let items = this.communityItems;
            let groupedItems = null;
            if (this.isGroupedBy === 'date') {
                groupedItems = groupBy(items, item => {
                    return (
                        item.date &&
                        formatRelativeGroup(
                            parseISO(item.date),
                            new Date(),
                            'week',
                            dateTypes.monthYear
                        )
                    );
                });
            } else {
                items = items.sort((a, b) => {
                    const a1 = a.heading.toLowerCase();
                    const b1 = b.heading.toLowerCase();
                    if (a1 < b1) {
                        return -1;
                    }
                    if (a1 > b1) {
                        return 1;
                    }
                    return 0;
                });
            }

            if (this.isGroupedBy === 'name') {
                groupedItems = groupBy(items, item => {
                    return item.heading.substring(0, 1);
                });
                const tmpSortedGroupedItems = {};
                Object.keys(groupedItems)
                    .sort()
                    .forEach(e => {
                        tmpSortedGroupedItems[e] = groupedItems[e];
                    });
                groupedItems = tmpSortedGroupedItems;
            }

            if (this.isGroupedBy === 'department') {
                groupedItems = groupBy(items, 'departmentCategory');
                const tmpGroupedItems = {};
                Object.keys(groupedItems).forEach(e => {
                    const dc = this.departmentCategoryItems.find(
                        x => x.id === parseInt(e)
                    );
                    const dcTitle = dc.title || e;
                    tmpGroupedItems[dcTitle] = groupedItems[e];
                });
                const tmpSortedGroupedItems = {};
                Object.keys(tmpGroupedItems)
                    .sort()
                    .forEach(e => {
                        tmpSortedGroupedItems[e] = tmpGroupedItems[e];
                    });
                groupedItems = tmpSortedGroupedItems;
            }

            if (groupedItems && groupedItems.null) {
                delete groupedItems.null;
            }

            const chainedGroup = chain(groupedItems)
                .map((items, group) => {
                    if (group) {
                        return {
                            group: group,
                            items: items,
                        };
                    }
                })
                .value();
            return chainedGroup;
        },
        communityItemCountries() {
            let arrCountries = [];
            this.communityItems.forEach(x => {
                arrCountries = [...arrCountries, ...x.countries];
            });
            return uniq(arrCountries);
        },
    },
    methods: {
        ...mapActions({
            fetchFilterItems: 'community/fetchFilterItems',
        }),
        getGroupName(groupName) {
            if (
                this.isGroupedBy === 'date' &&
                this.$i18n.te('community.groups.date.' + groupName)
            ) {
                return this.$i18n.t('community.groups.date.' + groupName);
            }
            return groupName;
        },
        loadMore() {
            this.page = this.page + 1;
            this.loadItems();
        },
        resetPage() {
            this.page = 1;
            return true;
        },
        loadItems() {
            this.$wait.start('loading community catalog');
            if (
                this.selectedCategory &&
                this.selectedTag &&
                this.selectedTag.name
            ) {
                this.fetchFilterItems({
                    page: this.page,
                    limit: 150,
                    text: this.searchTerm || null,
                    searchCategory: this.selectedCategory || null,
                    status: 'published',
                    countries: this.countryFilter ? [this.countryFilter] : null,
                }).then(data => {
                    this.$emit('setShowLoadMore', {
                        category: this.selectedCategory,
                        state: this.showLoadMore,
                    });
                    this.communityItems = data.result;
                    this.totalItems = data.total;
                    if (!this.initialLoaded) {
                        this.$emit('setInitialyLoaded', this.selectedCategory);
                    }
                    this.$wait.end('loading community catalog');
                });
            }
        },
        resetAndLoadItems() {
            this.resetPage();
            this.loadItems();
        },
    },
    watch: {
        communityItemCountries(to) {
            this.$emit('communityItemCountries', to);
        },
        countryFilter(to, from) {
            if (to !== from) {
                this.resetAndLoadItems();
            }
        },
    },
};
</script>

<style scoped>
@media screen and (min-width: 1500px) {
    .col2 {
        column-count: 2;
    }
}
</style>
