<template>
    <v-card
        class="v-card--material mx-auto"
        :class="{
            'mb-3': $vuetify.breakpoint.mdAndDown,
            'v-card--grow v-card--grow-actions': $vuetify.breakpoint.lgAndUp,
        }"
        color="secondary"
    >
        <div class="d-flex--v2 grow flex-wrap">
            <v-sheet
                color="primary lighten-1"
                max-height="90"
                width="auto"
                class="text-start v-card--material__heading mb-n6 elevation-6 pa-7"
            >
                <v-icon large color="white">
                    {{ icon }}
                </v-icon>
            </v-sheet>
            <div class="ml-6">
                <v-card-title @click="$emit('selectCategory')">
                    <span
                        class="text-h6 font-weight-light"
                        v-text="
                            $t(
                                'community.categoryItems.' +
                                    category.category +
                                    '.title'
                            )
                        "
                    />
                </v-card-title>
            </div>
        </div>
        <v-card-text>
            <p class="text-justify">
                {{
                    $t(
                        'community.categoryItems.' +
                            category.category +
                            '.intro'
                    )
                }}
            </p>
            <p class="mb-2 text-subtitle-1 primary--text">
                {{
                    $t(
                        'community.categoryItems.' +
                            category.category +
                            '.latestItems'
                    )
                }}
            </p>
            <v-divider></v-divider>
            <template v-for="(item, index) in category.result.items">
                <div
                    :key="item.id"
                    :class="{
                        'pb-3': index + 1 < category.result.items.length,
                    }"
                >
                    <CommunityResultPreview
                        removePadding
                        :selectedItem="selectedItem"
                        :item="item"
                        :isSelected="
                            selectedItem && item.id === selectedItem.id
                        "
                        @selectItem="$emit('selectItem', $event)"
                    />
                    <v-divider
                        v-if="index + 1 < category.result.items.length"
                    ></v-divider>
                </div>
            </template>
        </v-card-text>
        <v-card-actions>
            <v-btn
                @click="$emit('selectCategory')"
                large
                outlined
                block
                color="primary"
                class="btn--default-transform mb-3"
                >{{
                    $t(
                        'community.categoryItems.' +
                            category.category +
                            '.buttonLabel'
                    )
                }}
                ({{ category.result.total }})</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import CommunityResultPreview from './CommunityResultPreview.vue';
export default {
    props: {
        category: Object,
        selectedItem: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    components: {
        CommunityResultPreview,
    },
    computed: {
        icon() {
            if (this.category.category === 'penalty_fee') {
                return 'mdi-currency-eur';
            }
            if (this.category.category === 'scenario') {
                return 'mdi-presentation';
            }
            if (this.category.category === 'template') {
                return 'mdi-file-download-outline';
            }
            if (this.category.category === 'lea_news') {
                return 'mdi-newspaper';
            }
            return 'js-icon-network';
        },
    },
};
</script>
