var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"v-card--material mx-auto",class:{
        'mb-3': _vm.$vuetify.breakpoint.mdAndDown,
        'v-card--grow v-card--grow-actions': _vm.$vuetify.breakpoint.lgAndUp,
    },attrs:{"color":"secondary"}},[_c('div',{staticClass:"d-flex--v2 grow flex-wrap"},[_c('v-sheet',{staticClass:"text-start v-card--material__heading mb-n6 elevation-6 pa-7",attrs:{"color":"primary lighten-1","max-height":"90","width":"auto"}},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('div',{staticClass:"ml-6"},[_c('v-card-title',{on:{"click":function($event){return _vm.$emit('selectCategory')}}},[_c('span',{staticClass:"text-h6 font-weight-light",domProps:{"textContent":_vm._s(
                        _vm.$t(
                            'community.categoryItems.' +
                                _vm.category.category +
                                '.title'
                        )
                    )}})])],1)],1),_c('v-card-text',[_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.$t( 'community.categoryItems.' + _vm.category.category + '.intro' ))+" ")]),_c('p',{staticClass:"mb-2 text-subtitle-1 primary--text"},[_vm._v(" "+_vm._s(_vm.$t( 'community.categoryItems.' + _vm.category.category + '.latestItems' ))+" ")]),_c('v-divider'),_vm._l((_vm.category.result.items),function(item,index){return [_c('div',{key:item.id,class:{
                    'pb-3': index + 1 < _vm.category.result.items.length,
                }},[_c('CommunityResultPreview',{attrs:{"removePadding":"","selectedItem":_vm.selectedItem,"item":item,"isSelected":_vm.selectedItem && item.id === _vm.selectedItem.id},on:{"selectItem":function($event){return _vm.$emit('selectItem', $event)}}}),(index + 1 < _vm.category.result.items.length)?_c('v-divider'):_vm._e()],1)]})],2),_c('v-card-actions',[_c('v-btn',{staticClass:"btn--default-transform mb-3",attrs:{"large":"","outlined":"","block":"","color":"primary"},on:{"click":function($event){return _vm.$emit('selectCategory')}}},[_vm._v(_vm._s(_vm.$t( 'community.categoryItems.' + _vm.category.category + '.buttonLabel' ))+" ("+_vm._s(_vm.category.result.total)+")")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }