<template>
    <div>
        <portal to="appBarNavigationTitle">
            <span v-t="selectedCategory ? 'community.categoryItems.' + selectedCategory + '.title' : 'community.headline'"/>
        </portal>
        <portal to="appBarNavigationAction">
            <div class="d-flex flex-row justify-end align-center">
                <div v-if="selectedCategory">
                    <BaseBackButton :route="{name: 'CommunityList'}" />
                </div>
                <div v-else>
                    <BaseBackButton />
                </div>
                <div>
                    <CommunitySearch v-model="searchTerm2" />
                </div>
                <div v-if="false && selectedCategory">
                    <v-menu
                        v-if="!countryFilter"
                        offset-y
                        class="v-menu--inline"
                    >
                        <template v-slot:activator="{ on: onMenu }">
                            <v-tooltip
                                v-on="onMenu"
                                bottom
                                color="primary"
                                class="d-inline-flex"
                            >
                                <template
                                    v-slot:activator="{ on: onTooltip }"
                                >
                                    <v-btn
                                        v-on="{
                                            ...onTooltip,
                                            ...onMenu,
                                        }"
                                        color="grey lighten-5"
                                        class="ma-2 btn--default-transform primary--text"
                                        fab
                                        small
                                        depressed
                                    >
                                        <v-icon v-text="'mdi-earth'" />
                                    </v-btn>
                                </template>
                                <span v-t="'community.countryFilterTooltip'" />
                            </v-tooltip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="country in communityItemCountries"
                                :key="country.countryCode"
                                @click="
                                    countryFilter = country.countryCode
                                "
                                :class="{
                                    primary:
                                        countryFilter ===
                                        country.countryCode,
                                }"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        :class="{
                                            'white--text':
                                                countryFilter ===
                                                country.countryCode,
                                        }"
                                        >{{
                                            country.countryName
                                        }}</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-tooltip
                        v-if="countryFilter"
                        bottom
                        color="primary"
                        class="d-inline-flex"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                @click="countryFilter = null"
                                color="grey lighten-5"
                                class="ma-2 btn--default-transform primary--text"
                            >
                                <v-icon left size="18" class="pt-0"
                                    >mdi-close</v-icon
                                >
                                {{ countryFilterName }}
                            </v-btn>
                        </template>
                        <span>{{
                            $t('community.resetCountryFilterTooltip')
                        }}</span>
                    </v-tooltip>
                </div>
            </div>
        </portal>
        <v-container fluid>
            <v-row
                v-if="!selectedCategory"
                justify="space-around"
                class="pt-12 fill-height"
            >
                <div v-if="$wait.is('loading community teaser')">
                    <v-icon color="primary" large>mdi-loading mdi-spin</v-icon>
                </div>
                <template v-else v-for="category in latestCommunityItems">
                    <v-col
                        v-if="category.result.total > 0"
                        :class="{'mb-12': $vuetify.breakpoint.lgOnly}"
                        cols="12"
                        lg="6"
                        xl="4"
                        :key="category.category"
                    >
                        <LatestCommunityItemCategory
                            :category="category"
                            :selectedItem="selectedItem"
                            @selectCategory="selectCategory(category.category)"
                            @selectItem="selectItem($event, true, true)"
                            :selectedCategory="selectedCategory"
                        />
                    </v-col>
                </template>
            </v-row>
            <v-row>
                <template v-if="selectedCategory">
                    <v-col class="pa-1" cols="12" sm="11" md="8" lg="4" xl="3">
                        <CommunityCatalog
                            v-if="
                                selectedCategory &&
                                    selectedTag &&
                                    selectedTag.name
                            "
                            :selectedCategory="selectedCategory"
                            :selectedTag="selectedTag"
                            :selectedItem="selectedItem"
                            :initialLoaded="itemInitialyLoaded"
                            :departmentCategoryItems="departmentCategoryItems"
                            @setInitialyLoaded="setInitialyLoaded($event)"
                            :showLoadMore="itemShowLoadMore"
                            @setShowLoadMore="setShowLoadMore($event)"
                            @selectItem="selectItem($event)"
                            @communityItemCountries="setCommunityItemCountries($event)"
                            :searchTerm="searchTerm"
                            :countryFilter="countryFilter"
                            ref="communityFilterResult"
                        />
                    </v-col>
                </template>
                <v-col
                    class="pa-1"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="5"
                    offset-lg="1"
                    offset-xl="1"
                >
                    <div v-if="selectedItem">
                        <div
                            v-if="
                                $vuetify.breakpoint.lgAndUp &&
                                    !forceModalPreview
                            "
                            class="position-lg-fixed setWidthFromFlex"
                        >
                            <ItemPreview
                                :selectedItem="selectedItem"
                                :forceModalPreview="forceModalPreview"
                                :departmentCategoryItems="
                                    departmentCategoryItems
                                "
                                @closeItem="closeItem"
                                ref="itemPreview"
                                style="height: calc(100vh - 150px);overflow:auto;"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div
            v-if="
                selectedItem &&
                    ($vuetify.breakpoint.mdAndDown || forceModalPreview)
            "
        >
            <v-dialog
                v-model="itemPreviewDialog"
                :max-width="
                    $vuetify.breakpoint.smAndDown
                        ? '90%'
                        : $vuetify.breakpoint.mdAndDown
                        ? '70%'
                        : $vuetify.breakpoint.lg
                        ? '60%'
                        : '30%'
                "
            >
                <ItemPreview
                    :selectedItem="selectedItem"
                    :forceModalPreview="forceModalPreview"
                    :departmentCategoryItems="departmentCategoryItems"
                    @closeItem="closeItem"
                />
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce, isEqual } from 'lodash';
import CommunitySearch from '../search/CommunitySearch.vue';
import LatestCommunityItemCategory from './LatestCommunityItemCategory.vue';
import CommunityCatalog from './CommunityCatalog.vue';
import ItemPreview from './ItemPreview.vue';
import titleMixin from '@/mixins/titleMixin';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';

export default {
    name: 'CommunityListIndex',
    mixins: [ScrollHeaderMixin, titleMixin],
    components: {
        CommunitySearch,
        LatestCommunityItemCategory,
        CommunityCatalog,
        ItemPreview,
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    title() {
        return this.communityTitle;
    },
    data() {
        return {
            latestCommunityItems: [],
            communityItemCountries: [],
            countryFilter: null,
            searchTerm: null,
            searchTerm2: null,
            routeCategory: this.$route.params.category,
            selectedCategory: null,
            selectedItem: null,
            selectedTag: null,
            itemPreviewDialog: false,
            forceModalPreview: false,
            categoryItems: [
                {
                    id: null,
                    title: this.$t('community.overview'),
                },
                {
                    id: 'authority_news',
                    title: this.$t('community.categoryItems.authority_news.title'),
                    description: this.$t('community.categoryItems.authority_news.description'),
                    initialLoaded: false,
                    showLoadMore: false,
                },
                {
                    id: 'template',
                    title: this.$t('community.categoryItems.template.title'),
                    description: this.$t('community.categoryItems.template.description'),
                    initialLoaded: false,
                    showLoadMore: false,
                },
                {
                    id: 'scenario',
                    title: this.$t('community.categoryItems.scenario.title'),
                    description: this.$t('community.categoryItems.scenario.description'),
                    initialLoaded: false,
                    showLoadMore: false,
                },
                {
                    id: 'penalty_fee',
                    title: this.$t('community.categoryItems.penalty_fee.title'),
                    description: this.$t('community.categoryItems.penalty_fee.description'),
                    initialLoaded: false,
                    showLoadMore: false,
                },
                {
                    id: 'lea_news',
                    title: this.$t('community.categoryItems.lea_news.title'),
                    description: this.$t('community.categoryItems.lea_news.description'),
                    initialLoaded: false,
                    showLoadMore: false,
                } /** ,
        {
          id: 'legitimate_interest',
          title: 'Berechtigtes Interesse',
          description: 'Alle berechtigten Interessen anzeigen'
        } */,
            ],
        };
    },
    computed: {
        ...mapGetters({
            departmentCategoryItems: 'departmentCategories/getItems',
            countryItems: 'countries/getItems',
        }),
        itemInitialyLoaded() {
            const item = this.categoryItems.find(
                x => x.id === this.selectedCategory
            );
            return item ? item.itemInitialyLoaded : false;
        },
        itemShowLoadMore() {
            if (!this.categoryItems) {
                return false;
            }
            const item = this.categoryItems.find(
                x => x.id === this.selectedCategory
            );
            return item ? item.showLoadMore : false;
        },
        communityTitle() {
            if (this.selectedCategory && !this.selectedItem) {
                return `${this.$t(
                    'community.categoryItems.' +
                        this.selectedCategory +
                        '.title'
                )} - ${this.$t('community.headline')}`;
            }
            if (
                this.selectedCategory &&
                this.selectedItem &&
                this.selectedItem.heading
            ) {
                return `${this.selectedItem.heading} - ${this.$t(
                    'community.categoryItems.' +
                        this.selectedCategory +
                        '.title'
                )} - ${this.$t('community.headline')}`;
            }
            return this.$t('community.headline');
        },
        countryFilterName() {
            if (this.countryFilter) {
                const tmpCountry = this.countryItems.find(
                    x => x.countryCode === this.countryFilter
                );
                if (tmpCountry && tmpCountry.countryName) {
                    return tmpCountry.countryName;
                }
            }
            return this.$t('community.countrySelection');
        },
    },
    methods: {
        ...mapActions({
            fetchCountries: 'countries/fetch',
            fetchLastFilterItems: 'community/fetchLastFilterItems',
            fetchDepartmentCategories: 'departmentCategories/fetch',
            fetchFilterItems: 'community/fetchFilterItems',
        }),
        setInitialyLoaded(category) {
            const itemIndex = this.categoryItems.findIndex(
                x => x.id === category
            );
            this.$set(
                this.categoryItems[itemIndex],
                'itemInitialyLoaded',
                true
            );
        },
        setShowLoadMore(payload) {
            const itemIndex = this.categoryItems.findIndex(
                x => x.id === payload.category
            );
            this.$set(
                this.categoryItems[itemIndex],
                'showLoadMore',
                payload.state
            );
        },
        async fetchData() {
            this.$wait.start('pageLoader');
            this.fetchLoader = true;

            await Promise.all([
                this.fetchCountries(),
                this.fetchDepartmentCategories(),
            ]);

            this.fetchLoader = false;
            this.dataFetched();
            this.$wait.end('pageLoader');
        },
        selectCategory(category) {
            if (this.selectedCategory === category) {
                this.$router.push({ name: 'CommunityList' });
                this.selectedItem = null;
            } else {
                const id = this.$route.params.id;
                const paramObject = { category: category };
                if (id) {
                    paramObject.id = id;
                }
                const params = JSON.parse(JSON.stringify(paramObject));
                if (this.$route.params.category !== category) {
                    this.$router.push({ name: 'CommunityList', params: params });
                }
            }
            this.updateHeaders();
        },
        selectCategoryFromRouteChange(category) {
            return new Promise(resolve => {
                this.selectedTag = { name: 'allTags' };
                if (this.selectedCategory !== category) {
                    this.selectedCategory = category;
                    this.selectedItem = null;
                    this.countryFilter = null;
                }
                resolve();
            });
        },
        dataFetched() {
            this.initScrollHeader();
            if (this.routeCategory) {
                this.selectCategory(this.routeCategory);
                this.selectCategoryFromRouteChange(this.routeCategory);
            }
        },
        selectTag(objTypeCategory) {
            this.closeItem();
            if (
                this.selectedTag &&
                this.selectedTag.name === objTypeCategory.name
            ) {
                this.selectedTag = null;
            } else {
                this.selectedTag = objTypeCategory;
            }
        },
        selectItem(objPreview, forceModal, disableRouteChange) {
            if (!this.$wait.is('loading community item')) {
                forceModal = !!forceModal;
                disableRouteChange = !!disableRouteChange;
                this.forceModalPreview = forceModal;
                if (this.$refs.itemPreview && this.$refs.itemPreview.$el) {
                    this.$refs.itemPreview.$el.scrollTop = 0;
                }
                if (
                    this.selectedItem &&
                    this.selectedItem.id === objPreview.id
                ) {
                    if (disableRouteChange) {
                        this.selectedItem = null;
                    } else {
                        this.closeItem();
                    }
                } else {
                    this.selectedItem = objPreview;
                    if (
                        this.$vuetify.breakpoint.lgAndUp &&
                        !this.forceModalPreview
                    ) {
                        this.setWidthFromFlex();
                    } else {
                        this.itemPreviewDialog = true;
                    }
                    const routeObj = {
                        name: 'CommunityList',
                        params: {
                            category: null,
                        },
                    };
                    if (this.selectedCategory) {
                        routeObj.params.category = this.selectedCategory;
                    }
                    if (objPreview.id) {
                        routeObj.params.id = objPreview.id;
                    }
                    if (!disableRouteChange) {
                        this.$router.push(routeObj);
                    }
                }
            }
        },
        closeItem() {
            this.selectedItem = null;
            const routeObj = {
                name: 'CommunityList',
                params: {
                    category: null,
                },
            };
            if (this.selectedCategory) {
                routeObj.params.category = this.selectedCategory;
            }
            if (!this.forceModalPreview) {
                this.$router.push(routeObj);
            }
        },
        async setWidthFromFlex() {
            await this.$nextTick();
            const that = this;
            const setWidthFromFlex = document.querySelectorAll('.setWidthFromFlex');
            Array.prototype.forEach.call(setWidthFromFlex, function(el) {
                const parent = el.closest('.flex');
                if (that.$vuetify.breakpoint.lgAndUp) {
                    if (el && parent) {
                        let width = parent.offsetWidth;
                        const style = getComputedStyle(parent);
                        width -=
                            parseInt(style.paddingLeft) +
                            parseInt(style.paddingRight);
                        el.style.width = width + 'px';
                    }
                } else {
                    if (el) {
                        el.style.width = 'auto';
                    }
                    that.closeItem();
                }
            });
        },
        async fetchLatestCommunityItems() {
            this.$wait.start('loading community teaser');
            this.fetchLastFilterItems({
                page: 1,
                limit: 2,
            }).then(data => {
                this.latestCommunityItems = data.result;
                this.$wait.end('loading community teaser');
            });
        },
        setRouteParamsFromRequest() {
            if (this.$route.params.id) {
                this.$wait.start('loading community item');
                this.fetchFilterItems({
                    page: 1,
                    limit: 1,
                    id: this.$route.params.id,
                    status: 'published',
                })
                    .then(data => {
                        this.selectedItem = data.result[0];
                        if (
                            this.$vuetify.breakpoint.lgAndUp &&
                            !this.forceModalPreview
                        ) {
                            this.setWidthFromFlex();
                        } else {
                            this.itemPreviewDialog = true;
                        }
                        this.$wait.end('loading community item');
                    })
                    .catch(() => {
                        this.$wait.end('loading community item');
                    });
            }
        },
        fetchDataAndSetRouteParamsFromRequest() {
            this.fetchData().then(() => {
                if (!this.$route.params || !this.$route.params.category) {
                    this.fetchLatestCommunityItems().then(() => {
                        this.setRouteParamsFromRequest();
                    });
                } else {
                    this.setRouteParamsFromRequest();
                }
            });
        },
        setCommunityItemCountries(countries) {
            const arrCountries = [];
            if (countries && countries.length) {
                countries.forEach(x => {
                    arrCountries.push(
                        this.countryItems.find(y => y.countryCode === x)
                    );
                });
            }
            this.communityItemCountries = arrCountries;
        },
    },
    created() {
        this.fetchDataAndSetRouteParamsFromRequest();
        window.addEventListener('resize', this.setWidthFromFlex);
    },
    watch: {
        $route(to, from) {
            if (!isEqual(to, from)) {
                if (
                    to.params.category &&
                    to.params.category !== from.params.category
                ) {
                    this.routeCategory = to.params.category;
                    this.selectCategoryFromRouteChange(this.routeCategory).then(
                        () => {
                            if (this.$refs.communityFilterResult) {
                                this.$refs.communityFilterResult.resetAndLoadItems();
                            }
                        }
                    );
                }
                if (
                    to.params &&
                    to.params.id &&
                    to.params.id !== from.params.id
                ) {
                    this.setRouteParamsFromRequest();
                } else if (
                    (!to.params || !to.params.category) &&
                    to.params.category !== from.params.category
                ) {
                    this.routeCategory = null;
                    this.selectedCategory = null;
                    this.selectedItem = null;
                    this.itemPreviewDialog = false;
                    this.countryFilter = null;
                    this.fetchDataAndSetRouteParamsFromRequest();
                }
            }
        },
        searchTerm: debounce(function(to, from) {
            if (to !== from && this.$refs.communityFilterResult) {
                this.$refs.communityFilterResult.resetAndLoadItems();
            }
        }, 500),
        countryFilter(to, from) {
            if (to !== from && this.selectedItem) {
                this.closeItem();
            }
        },
    },
    beforeDestroy() {
        this.routeCategory = null;
        window.removeEventListener('resize', this.setWidthFromFlex);
    },
};
</script>
